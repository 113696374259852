
.catalogs-container {
    grid-column-gap: 10px;
    grid-row-gap: 200px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    display: grid;
}

@media screen and (max-width: 767px) {
    .catalogs-container {
        flex-direction: column;
        align-content: stretch;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        grid-row-gap: 40px;
    }
}

.catalog-box {
    display: flex;
    width: 610px;
    height: 488px;
    overflow: hidden;
    flex-direction: column;
    position: relative;
}

.catalog-box > .catalog-img {
    /*background-color: black;*/
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    flex:1;
    display: flex; /* Allows centering of image */
    align-items: center; /* Centers image vertically */
    justify-content: center;
}

.catalog-box > .catalog-img > img {
    max-width: 100%; /* Limit width to the container */
    max-height: 100%; /* Limit height to the container */
    height: auto; /* Maintain aspect ratio */
    width: auto; /* Maintain aspect ratio */
    object-fit: contain;
    margin: 0;
}

@media screen and (max-width: 1919px) {
    .catalog-box {
        width: 41vw;
        height: 32.8vw;
    }
}

@media screen and (max-width: 1439px) {
    .catalog-box {
        width: 40vw;
        height: 32vw;
    }
}

@media screen and (max-width: 991px) {
    .catalog-box {
        width: 37vw;
        height: 29.6vw;
    }
}

@media screen and (max-width: 767px) {
    .catalog-box {
        width: 84vw;
        height: 67.2vw;
    }
}

@media screen and (max-width: 479px) {
    .catalog-box {
        width: 79vw;
        height: 70vw;
    }
}

.catalog-box > .catalog-title {
    font-family: "Questrial", sans-serif;
    font-weight: 100;
    /*background: #ffffff;*/
    color: #ffffff;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    text-align: center;
}

.catalog-box > .catalog-title > h2 {
    margin: 1em;
    font-weight: 100;
}