@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Questrial&display=swap');
@import url("./css/film.css");
@import url("./css/filmPage.css");
@import url("./css/menu.css");
@import url("./css/writings.css");
@import url("./css/interview.css");
@import url("./css/press.css");
@import url("./css/about.css");
@import url("./css/catalog.css");

html, body {
    background: #ffffff;
    overflow-x: hidden;
}

#grain {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 102;
    pointer-events: none;
    display: block;
    background: url("./assets/grain.png");
    background-size: 200px 200px;
}

@media screen and (max-width: 767px) {
    #grain {
        background-size: 100px 100px;
    }
}

.motif {
    width: 200px;
    height: 200px;
}

.motif > img {
    transform: scale(0.7);
}

@media screen and (max-width: 479px) {
    .motif > img {
        max-width: 60vw;
        max-height: 60vw;
    }
}

.avikunthakHome {
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    font-family: "EB Garamond", serif;
    text-align: center;
    color: #000;
    transform: translate(-50%, -50%);
    z-index: 3;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avikunthakTitle {
    display: block;
    max-width: calc(100% - 2em);
    letter-spacing: 0.2em;
    font-size: 5em;
    font-weight: 500;
    color: #ffffff;
    mix-blend-mode: difference;
}

.page-heading {
    max-width: 80%;
    font-family: "EB Garamond", serif;
    margin-top: 2em;
    font-size: 4em;
    color: #000;
    margin-bottom: 2em;
}

@media screen and (max-width: 959px) and (orientation: portrait) {
    .avikunthakTitle {
        font-size: 4.1em;
        letter-spacing: .133em;
    }

    .page-heading {
        font-size: 3.3em;
    }

    h2 {
        font-size: 1.23em;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .avikunthakTitle {
        font-size: 10vw;
        letter-spacing: .16em;
    }

    .page-heading {
        font-size: 9vw;
    }

    h2 {
        font-size: 4.2vw;
    }
}

.footer {
    display: flex;
    height: 20vh;
    padding: 0 10%;
    flex-direction: row;
    align-items: center;
    font-family: "Questrial", sans-serif;
    font-weight: bold;
}

.footer.white {
    color: #ffffff;
}

.footer > div {
    flex: 1;
}

.footer > div:last-child {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media screen and (max-width: 767px) and (orientation: portrait) {

    .footer {
        margin-top: 10vh;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer > div {
        margin-top: 1em;
        flex: 0 1 auto;
    }

    .footer > div:last-child {
        align-items: flex-start;
    }

}

.preloader {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #c52137;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preloader-animation {
    width: 400px;
    height: 400px;
    background: url("./assets/preloader-img.png");
    background-size: cover;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: rotating 5s linear infinite;
    -moz-animation: rotating 5s linear infinite;
    -ms-animation: rotating 5s linear infinite;
    -o-animation: rotating 5s linear infinite;
    animation: rotating 5s linear infinite;
}

.preloader-text {
    color: #000000;
    font-family: "EB Garamond", serif;
    letter-spacing: 0.2em;
    font-size: 5em;
    font-weight: 500;
    text-align: center;
    max-width: calc(100% - 2em);
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .preloader-animation {
        width: 50vw;
        height: 50vw;
    }
}

@media screen and (max-width: 959px) and (orientation: portrait) {
    .preloader-text {
        font-size: 4.1em;
        letter-spacing: .133em;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .preloader-text {
        font-size: 10vw;
        letter-spacing: .16em;
    }
}
