
.film-carousel > img{
    max-height: 50vh;
    max-width: 50vw;
}

.film-carousel {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    /*align-self: flex-start;*/
}

.film-details {
    font-family: "Questrial", sans-serif;
    margin-top: 2em;
    width: 80%;
}

.film-details-1 {
    margin: 2em 0em;
    font-size: 2em;
    text-align: justify;
    line-height: 1.5em;
}

@media screen and (max-width: 959px) and (orientation: portrait) {
    .film-details-1 {
        font-size: 1.65em;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .film-details-1 {
        font-size: 4.5vw;
    }
}

.film-details-2 {
    margin: 10vh 0em;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 1em;
}

.film-trailer {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    pointer-events: none;
    flex: 1;
    background: #000000;
    /*z-index: 101;*/
}

.film-gallery {
    display: block;
    position: relative;
    flex-grow: 1;
    /*background-color: #000000;*/
    color: #ffffff;
    height: 100vh;
    background-size: contain;
    background-position: center 0;
    background-repeat: no-repeat;
}

.film-specs {
    margin: 0em 1em;
    font-size: 1.2em;
}

@media screen and (max-width: 1224px) {
    .film-details-2 {
        flex-direction: column;
        align-items: flex-end;
    }

    .film-specs {
        margin: 1em 10% 1em 0;
    }

    .film-gallery {
        width: 100%;
        flex-shrink: 0;
        background-position: center bottom;
    }

}

@media screen and (max-width: 1224px) and (min-width: 480px){
    .film-trailer {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .film-details-2 {
        flex-direction: column;
        align-items: center;
    }

    .film-gallery {
        height: 50vh;
    }

    .film-trailer {
        /*height: 80vw;*/
        /*margin: 10vh 0;*/
        /*transform: rotate(90deg);*/
    }
}

.film-details-3 {
    margin: 4em 0em;
    font-size: 2em;
}

.film-credits {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0.5em 0em;
    flex-wrap: wrap;
}

.film-credits > div {
    margin: 0 0.5em;
}

.film-credits > div:first-child {
    margin-left: 0;
    justify-self: flex-start;
}
.film-credits > div:last-child{
    margin-right: 0;
}

.film-credits-dash {
    height: 2.5px;
    background: #000000;
    flex: 1;
}

.film-credits {
    flex-direction: column;
    margin: 1em 0em;
}

.film-credits > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 0;
}

.film-credits > div > div {
    margin: 0em 0.5em;
}

.film-credits > div > div:first-child {
    margin-left: 0
}

.film-credits > div > div:last-child {
    margin-right: 0
}

@media screen and (max-width: 959px) and (orientation: portrait) {
    .film-details-3 {
        font-size: 1.65em;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .film-details-3 {
        font-size: 4.5vw;
    }
}

.film-details-4 > div > b {
    margin-right: 1em;
}


