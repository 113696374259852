.press {
    flex:1;
    font-family: "Questrial", sans-serif;
    color: #ffffff;
    padding-right: 10vh;
}

.press > div {
    margin-bottom: 10vh;
}

.press-details {
    display: flex;
    flex-direction: row;
    margin-bottom: 2em;
    align-items: flex-start;
}

.press-details > b {
    font-size: 2em;
    margin-right: 1em;
}

.press-details > div {
    font-size: 2em;
}

.press-details a {
    cursor: pointer !important;
    text-decoration: none;
    color: #ffffff;
}

@media screen and (max-width: 959px) and (orientation: portrait) {
    .press-details > b,
    .press-details > div {
        font-size: 1.65em;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .press-details > b,
    .press-details > div {
        font-size: 4.5vw;
    }
}


.press-film-strip {
    width: 10vw;
    background-image: url("../assets/press/film_strip2.png");
    position: relative;
    margin-right: 4em;
    background-size: contain;
    background-position: center center;
}

.press-container {
    display: flex;
    flex-direction: row;
    width: 90vw;
    justify-content: center;
}

@media screen and (max-width: 959px) and (orientation: portrait) {
    .press-container {
        width: 100%;
    }

    .press-film-strip {
        width: 4em;
        margin-right: 1em;
    }

    .press {
        padding-right: 2em;
    }
}

