.menu {
    top: 0;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}


@media screen and (max-width: 767px) and (orientation: portrait) {
    .menu {
        height: 10vh;
    }
}

.burger {
    display: block;
    height: 2em;
    width: 2em;
    position: fixed;
    overflow: hidden;
    background: transparent;
    z-index: 101;
    border: #ffffff 5px solid;
}

.burger > div {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background: #000000;
    transition: all 0.1s ease;
    z-index: 102;
}

.burger > span {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 100;
    mix-blend-mode: multiply;
}

.burger > div:first-child {
    transform: translateY(-50%) translateY(-2px);
}

.burger > div:last-child {
    transform: translateY(-50%) translateY(2px);
}

.burger.clicked > div:first-child {
    background: #ffffff;
    transform: translateY(-50%) rotate(45deg);
}

.burger.clicked > div:last-child {
    background: #ffffff;
    transform: translateY(-50%) rotate(-45deg);
}

.burger.clicked {
    border-color: #000000 !important;
}

.burger.clicked > span {
    background: #000000 !important;
}

.burger.white > div {
    background: #ffffff;
}


.menu-mobile {
    background: #000000;
    color: #ffffff;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    top:0;
    left: 0;
    z-index: 99;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-mobile .menu-mobile-bg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../assets/grain.png");
    background-size: 200px 200px;
    pointer-events: none;
    transition: all 0.3s ease;
    opacity: 0.1;
}

.menu-mobile.hidden {
    display: none;
}

.menu-mobile.hidden .menu-mobile-bg {
    transition: all 0.3s ease;
    opacity: 0;
}

.page-link {
    font-size: 1.1em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #000 !important;
    text-decoration: none !important;
    font-family: "Questrial", sans-serif;
    margin: 0 2em;
}

.menu-mobile .page-link {
    font-size: 1.2em;
    margin: 1em 0;
}

.menu-mobile-desktop {
    align-items: center;
    justify-content: flex-end;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    /*width: 80vw;*/
    height: 60vh;
    padding: 20vh 0;
}

.menu-mobile-desktop .page-link {
    margin: 0 2em 0 2em;
    font-size: 3em;
}