.about {
    width: 80%;
    font-family: "Questrial", sans-serif;
    color: #ffffff;
}

.about > p {
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 2em;
    text-align: justify;
}

.about-img {
    max-width: 50%;
    max-height: 100vh;
    display: block;
    position: relative;
    float: left;
    margin-right: 2em;
    margin-bottom: 2em;
}

.about-img > img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: contain;
}

.about h2 {
    font-size: 2em;
    margin-top: 3em;
    margin-bottom: 1em;
}

.about h3 {
    margin-top: 2em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
}

.about h4 {
    margin: 0;
    margin-bottom: 0.1em;
    font-size: 1.2em;
    font-weight: normal;
    font-style: italic;
}

@media screen and (max-width: 767px) {
    .about {
        display: flex;
        flex-direction: column;
    }

    .about-img {
        max-width: 100%;
        margin-right: 0;
    }
}

@media screen and (max-width: 959px) and (orientation: portrait) {

    .about h2 {
        font-size: 1.65em;
    }

    .about > p {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {

    .about h2 {
        font-size: 5.6vw;
    }

    .about > p {
        font-size: 4vw;
    }
}