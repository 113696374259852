
.films-container {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    display: grid;
}

@media screen and (max-width: 767px) {
    .films-container {
        flex-direction: column;
        align-content: stretch;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        grid-row-gap: 40px;
    }
}

.film-box {
    display: flex;
    width: 610px;
    height: 488px;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    /*border-radius: 2em;*/
}

.film-box > div:first-child {
    background-size: auto 140%;
    /*background-size: cover;*/
    /*background-position: center;*/
    /*background-attachment: fixed;*/
    flex:1;
}

@media screen and (max-width: 1919px) {
    .film-box {
        width: 41vw;
        height: 32.8vw;
    }
}

@media screen and (max-width: 1439px) {
    .film-box {
        width: 40vw;
        height: 32vw;
    }
}

@media screen and (max-width: 991px) {
    .film-box {
        width: 37vw;
        height: 29.6vw;
    }
}

@media screen and (max-width: 767px) {
    .film-box {
        width: 84vw;
        height: 67.2vw;
    }
}

@media screen and (max-width: 479px) {
    .film-box {
        width: 79vw;
        height: 70vw;
    }
}

.film-box > div:last-child {
    font-family: "Questrial", sans-serif;
    font-weight: 100;
    color: #000000;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*height: 15%;*/
}

.film-box > div:last-child > h2 {
    /*margin: 1em;*/
    font-weight: 100;
}

.film-box > div:last-child > div {
    flex: 1;
    display: block;
    height: 1.5px;
    margin: 0em 1em;
    overflow: hidden;
}

.film-box > div:last-child > div > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    background: #000000;
    left: 0;
    transition: all 0.3s ease;
}

.film-box:hover > div:last-child > div > div {
    left: 100%;
}